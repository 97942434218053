import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import * as common from "../common"
import SEO from "../components/seo"

export default ({ data, classes, pageContext }) => {
  const competition = data.allMysqlCompetitions.edges[0].node
  common.getCompetitionAltname(
    competition,
    data.allMysqlAltnames.edges,
    pageContext
  )
  const title = competition.altName + " " + pageContext.seasonLabel
  const totalAverage = common.getTotalAverage(
    data.allMysqlCompSeasonDataset.edges
  )
  const gameMax = common.getHighestAttendance(
    data.allMysqlCompSeasonDataset.edges
  )
  const gameMin = common.getLowestAttendance(
    data.allMysqlCompSeasonDataset.edges
  )
  const bubbleChartData = JSON.stringify(
    common.getCompSeasonBubbleChartData(
      data.allMysqlCompSeasonDataset.edges,
      data.allMysqlCountries.edges
    )
  )
  const bubbleChartCountries = JSON.stringify(
    common.getCompSeasonBubbleChartCountries(
      data.allMysqlCompSeasonDataset.edges,
      data.allMysqlCountries.edges
    )
  )
  const averageByCountry = common.getAverageByCountry(
    data.allMysqlCompSeasonDataset.edges,
    data.allMysqlCountries.edges
  )

  const averageByMonth = common.getAverageByMonth(
    data.allMysqlCompSeasonDataset.edges
  )

  const averageByStage = common.getAverageByStage(
    data.allMysqlCompSeasonDataset.edges
  )

  const averageByTeamHome = common.getAverageByTeamHome(
    data.allMysqlCompSeasonDataset.edges,
    data.allMysqlTeams.edges,
    data.allMysqlCountries.edges
  )
  const averageByTeamAway = common.getAverageByTeamAway(
    data.allMysqlCompSeasonDataset.edges,
    data.allMysqlTeams.edges,
    data.allMysqlCountries.edges
  )
  const averageByTeamHomeAway = common.getAverageByTeamHomeAway(
    data.allMysqlCompSeasonDataset.edges,
    data.allMysqlTeams.edges,
    data.allMysqlCountries.edges,
    competition
  )

  return (
    <Layout>
      <SEO title={title} />
      <div>
        <h1>{title}</h1>
        <section>
          <div className="row justify-content-center subsection">
            <div className="col-5 col-md-4 subtitle">
              Total average attendance by game:
            </div>
            <div className="col-5 col-md-4 text-left subtitle">
              {totalAverage}
            </div>
          </div>
          <div className="row justify-content-center subsection">
            <div className="col-5 col-md-4 text-left">
              Highest recorded attendance game:
            </div>
            <div className="col-5 col-md-4 text-left">
              {gameMax.node && common.dateToString(gameMax.node.gamedate)}:{" "}
              {gameMax.node && gameMax.node.team1} -
              {gameMax.node && gameMax.node.team2}
              <br /> Attendance: {gameMax.node && gameMax.node.attendance}
            </div>
          </div>
          <div className="row justify-content-center subsection">
            <div className="col-5 col-md-4 text-left">
              Lowest recorded attendance game:
            </div>
            <div className="col-5 col-md-4 text-left">
              {common.dateToString(gameMin.node && gameMin.node.gamedate)}:{" "}
              {gameMin.node && gameMin.node.team1} -
              {gameMin.node && gameMin.node.team2}
              <br /> Attendance: {gameMin.node && gameMin.node.attendance}
            </div>
          </div>
          <div className="row justify-content-center subsection">
            <div className="offset-1 col-12 col-md-3">
              <Link
                to={`/compseason/${pageContext.compId}/${pageContext.seasonId}/games`}
              >
                Attendances by game
              </Link>
            </div>
          </div>
        </section>
        <h2>Each game attendance by date</h2>
        <p>
          Average marked with red horizontal line. Hover on bubbles to see
          details. Some bubbles may overlap each other.
        </p>
        <p>
          <canvas id="attendanceBubbleChart"></canvas>
        </p>
        <hr />
        {competition.type !== "tournament" && (
          <>
            {averageByCountry.length > 1 && (<>
              <h2>Average attendance by country:</h2>
              <div
                className="row justify-content-around subsection"
                style={{ margin: "0 0 20px 0" }}
              >
                {averageByCountry.map((country, key) => (
                  <div className="offset-1 col-11 col-md-5" key={key}>
                    <div style={{ width: "40%", float: "left" }}>{country.country.name}:</div>
                    <div style={{ width: "50%", float: "right" }}>{country.average}</div>
                  </div>
                ))}
              </div>
              <div
                className="row justify-content-center subsection"
                style={{ margin: "0 0 20px 0" }}
              >
                <div className="col-12 col-md-8">
                  <canvas id="country-barchart"></canvas>
                </div>
              </div>
              <hr />
            </>)}
            <h2>Average attendance by month:</h2>
            <div
              className="row justify-content-center subsection"
              style={{ margin: "0 0 20px 0" }}
            >
              {averageByMonth.map((month, key) => (
                <div className="offset-1 col-11 col-md-5" key={key}>
                  <div style={{ width: "40%", float: "left" }}>{month.label}:</div>
                  <div style={{ width: "50%", float: "right" }}>{month.average}</div>
                </div>
              ))}
            </div>
            <div
              className="row justify-content-center subsection"
              style={{ margin: "0 0 20px 0" }}
            >
              <div className="col-12 col-md-8">
                <canvas id="month-linechart"></canvas>
              </div>
            </div>
            <hr />
          </>
        )}
        <h2>Average attendance by competition stage:</h2>
        <div
          className="row justify-content-center subsection"
          style={{ margin: "0 0 20px 0" }}
        >
          {averageByStage.map((stage, key) => (
            <div className="offset-1 col-11 col-md-5" key={key}>
              <div style={{ width: "40%", float: "left" }}>{stage.label}:</div>
              <div style={{ width: "50%", float: "right" }}>{stage.average}</div>
            </div>
          ))}
        </div>
        <div
          className="row justify-content-center subsection"
          style={{ margin: "0 0 20px 0" }}
        >
          <div className="col-12 col-md-8">
            <canvas id="stage-linechart"></canvas>
          </div>
        </div>
        <hr />
        {competition.type !== "tournament" && (
          <>
            <p>&nbsp;</p>
            <h2>Average attendance by team on home games:</h2>
            <div
              className="row justify-content-center subsection"
              style={{ margin: "0 0 20px 0" }}
            >
              {averageByTeamHome
                .slice(0)
                .sort((a, b) => parseInt(b.average) - parseInt(a.average))
                .map((team, key) => (
                  <div className="offset-1 col-12 col-md-3" key={key}>
                    <div style={{ width: "60%", float: "left" }}><Link to={`/team/${team.urlSlug}`}>{team.label}</Link>:</div>
                    <div style={{ width: "30%", float: "right" }}>{team.average}</div>
                  </div>
                ))}
            </div>
            <div
              className="row justify-content-center subsection"
              style={{ margin: "0 0 20px 0" }}
            >
              <div className="col-12 col-lg-8">
                <canvas id="team-home-chart"></canvas>
              </div>
            </div>
            <hr />
            <h2>Average attendance by team on away games:</h2>
            <div
              className="row justify-content-center subsection"
              style={{ margin: "0 0 20px 0" }}
            >
              {averageByTeamAway
                .slice(0)
                .sort((a, b) => parseInt(b.average) - parseInt(a.average))
                .map((team, key) => (
                  <div className="offset-1 col-12 col-md-3" key={key}>
                    <div style={{ width: "60%", float: "left" }}><Link to={`/team/${team.urlSlug}`}>{team.label}</Link>:</div>
                    <div style={{ width: "30%", float: "right" }}>{team.average}</div>
                  </div>
                ))}
            </div>
            <div
              className="row justify-content-center subsection"
              style={{ margin: "0 0 20px 0" }}
            >
              <div className="col-12 col-lg-8">
                <canvas id="team-away-chart"></canvas>
              </div>
            </div>
            <hr />
          </>
        )}
        <h2>
          Average attendance by team
          {competition.type !== "tournament" && (
            <> (home and away games combined)</>
          )}
        </h2>
        <div
          className="row justify-content-center subsection"
          style={{ margin: "0 0 20px 0" }}
        >
          {averageByTeamHomeAway
            .slice(0)
            .sort((a, b) => parseInt(b.average) - parseInt(a.average))
            .map((team, key) => (
              <div className="offset-1 col-12 col-md-3" key={key}>
                <div style={{ width: "60%", float: "left" }}>{team.urlSlug && (
                  <Link to={`/team/${team.urlSlug}`}>{team.label}</Link>
                )}
                {!team.urlSlug && team.label}:</div>
                <div style={{ width: "30%", float: "right" }}>{team.average}</div>
              </div>
            ))}
        </div>
        <div
          className="row justify-content-center subsection"
          style={{ margin: "0 0 20px 0" }}
        >
          <div className="col-12 col-lg-8">
            <canvas id="team-home-away-chart"></canvas>
          </div>
        </div>

        <Helmet
          script={[
            {
              type: "text/javascript",
              innerHTML: `
                if(document.getElementById('team-home-away-chart') == null){
                    window.onload = function() {
                      insertBubblechart('attendanceBubbleChart', ${bubbleChartData}, ${bubbleChartCountries}, '${title}', ${totalAverage});
                      insertCountryBarchart('country-barchart', ${JSON.stringify(
                        averageByCountry
                      )}, ${totalAverage});
                      insertLinechart('month-linechart', ${JSON.stringify(
                        averageByMonth
                      )}, ${totalAverage});
                      insertLinechart('stage-linechart', ${JSON.stringify(
                        averageByStage
                      )}, ${totalAverage});
                      insertTeamsBarchart('team-home-chart', ${JSON.stringify(
                        averageByTeamHome
                      )}, ${totalAverage});
                      insertTeamsBarchart('team-away-chart', ${JSON.stringify(
                        averageByTeamAway
                      )}, ${totalAverage});
                      insertTeamsBarchart('team-home-away-chart', ${JSON.stringify(
                        averageByTeamHomeAway
                      )}, ${totalAverage});
                  };
                  } else {
                    insertBubblechart('attendanceBubbleChart', ${bubbleChartData}, ${bubbleChartCountries}, '${title}', ${totalAverage});
                    insertCountryBarchart('country-barchart', ${JSON.stringify(
                      averageByCountry
                    )}, ${totalAverage});
                    insertLinechart('month-linechart', ${JSON.stringify(
                      averageByMonth
                    )}, ${totalAverage});
                    insertLinechart('stage-linechart', ${JSON.stringify(
                      averageByStage
                    )}, ${totalAverage});
                    insertTeamsBarchart('team-home-chart', ${JSON.stringify(
                      averageByTeamHome
                    )}, ${totalAverage});
                    insertTeamsBarchart('team-away-chart', ${JSON.stringify(
                      averageByTeamAway
                    )}, ${totalAverage});
                    insertTeamsBarchart('team-home-away-chart', ${JSON.stringify(
                      averageByTeamHomeAway
                    )}, ${totalAverage});
                  }
                `,
            },
          ]}
        />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($seasonId: String!, $compId: String!) {
    allMysqlCompSeasonDataset(
      filter: { season: { eq: $seasonId }, competition: { eq: $compId } }
      sort: { fields: gamedate }
    ) {
      edges {
        node {
          gamedate
          team1
          team2
          attendance
          season
          competition
          country
          stage
          location
        }
      }
    }
    allMysqlCountries {
      edges {
        node {
          id
          iso3
          color
          iso2
          name
        }
      }
    }
    allMysqlTeams {
      edges {
        node {
          altName
          shortName
          country
          urlSlug
          latitude
          longitude
        }
      }
    }
    allMysqlCompetitions(filter: { mysqlId: { eq: $compId } }) {
      edges {
        node {
          mysqlId
          label
          logo
          type
        }
      }
    }
    allMysqlLocations {
      edges {
        node {
          mysqlId
          country
          latitude
          longitude
        }
      }
    }
    allMysqlAltnames {
      edges {
        node {
          mysqlId
          entityType
          entityId
          season
          altValue
        }
      }
    }
  }
`
